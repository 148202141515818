import { useState, useEffect } from "react";

const isBrowser = typeof window !== "undefined";

const initAgeConfirm = () => {
  if (isBrowser) {
    const isSessionAgeConfirmed = !!window.sessionStorage.getItem("ageConfirmed");
    const entries = window.performance.getEntriesByType("navigation");
    if (entries[0].type === "reload" && isSessionAgeConfirmed) {
      window.sessionStorage.removeItem("ageConfirmed");
    }
  }
};

initAgeConfirm();

const useAgeConfirmed = () => {
  const [ageConfirmed, setAgeConfirmed] = useState(false);

  useEffect(() => {
    if (window.sessionStorage.getItem("ageConfirmed")) setAgeConfirmed(true);
  }, []);

  const handleConfirm = () => {
    setAgeConfirmed(true);
    window.sessionStorage.setItem("ageConfirmed", true);
  };

  const handleDecline = () => {
    if (isBrowser) {
      window.location.href = "https://www.google.com/";
    }
  };

  return {
    ageConfirmed,
    handleConfirm,
    handleDecline,
  };
};

export default useAgeConfirmed;
