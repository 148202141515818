import React from "react";
import styled from "@emotion/styled";
import { OuterLink } from "../components";
import config from "../config";
import fbIcon from "../assets/images/btn-snk-01.png";
import twitterIcon from "../assets/images/btn-snk-02.png";
import ageIcon from "../assets/images/csr-18.png";
import { useIntl } from "gatsby-plugin-intl";

const FooterContainer = styled.footer`
  width: 100%;
  background-color: #000000;
  padding: 30px 0 50px;
`;

const FooterBlock = styled.div`
  width: 590px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    width: 80%;
  }
`;

const FooterFollow = styled.div`
  font-style: italic;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
`;

const FooterFollowIcon = styled.a`
  display: inline-block;
  margin: 10px 15px;
`;

const FooterWarn = styled.div`
  display: flex;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 700;

  img {
    margin-right: 15px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    img {
      margin: 0 0 15px 0;
    }
  }
`;

const FooterLinks = styled.div`
  text-align: center;
  margin-top: 50px;

  > div {
    display: inline-block;
    margin: 0 5px;
  }

  a {
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    margin: 0 2px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
`;

export default function Footer() {
  const intl = useIntl();
  const { locale, formatMessage } = intl;

  return (
    <FooterContainer>
      <FooterBlock>
        <FooterFollow>
          <div>- FOLLOW US -</div>
          <FooterFollowIcon
            href={config.footer.snsLinks.facebook[locale]}
            target="_blank"
            rel="noreferrer noopener">
            <img src={fbIcon} alt="facebook-icon" />
          </FooterFollowIcon>
          <FooterFollowIcon
            href={config.footer.snsLinks.twitter[locale]}
            target="_blank"
            rel="noreferrer noopener">
            <img src={twitterIcon} alt="twitter-icon" />
          </FooterFollowIcon>
        </FooterFollow>

        {locale === "zh-Hant" && (
          <FooterWarn>
            <div>
              <img src={ageIcon} alt="age-icon" />
            </div>
            <div>{formatMessage({ id: "footer-warning" })}</div>
          </FooterWarn>
        )}

        <FooterLinks>
          <div>
            &#60;
            <OuterLink
              href={`${process.env.GATSBY_ROOT_PATH}/${locale}/privacy-policy`}
              text={formatMessage({ id: "footer-privacy" })}
            />
            &#62;
          </div>
          <div>
            &#60;
            <OuterLink
              href={`${process.env.GATSBY_ROOT_PATH}/${locale}/term-of-use`}
              text={formatMessage({ id: "footer-tos" })}
            />
            &#62;
          </div>
        </FooterLinks>
      </FooterBlock>
    </FooterContainer>
  );
}
