import React, { useEffect, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import config from "../config";
import { OuterLink } from "../components";
import { Z_INDEX } from "../utils/style";
import { LANGS_URLS_AND_DISPLAY, LANGS_URLS_FOR_ROOT } from "../utils/languages";
import menuIcon from "../assets/images/mobile/m-menu-btn-01.png";
import closeIcon from "../assets/images/mobile/m-menu-btn-x.png";
import menuNavBg from "../assets/images/mobile/m-menu-btn-02.png";
import menuSNSFacebook from "../assets/images/mobile/m-menu-btn-03.png";
import menuSNSTwitter from "../assets/images/mobile/m-menu-btn-04.png";
import menuLangBg from "../assets/images/mobile/m-menu-btn-05.png";

const MenuWrapper = styled.div`
  position: relative;
  z-index: ${Z_INDEX.cover};

  @media screen and (min-width: 1300px) {
    display: none;
  }
`;

const MenuIcon = styled.img`
  position: fixed;
  right: 10px;
  top: 10px;
  width: 50px;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    right: 20px;
    top: 20px;
    width: 60px;
  }
`;

const MenuPanel = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.78);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 60px;
  overflow-x: hidden;
  overflow-y: scroll;

  > a {
    display: block;
  }
`;

const MenuCloseBtn = styled.button`
  width: 28px;
  height: 36px;
  background: url(${closeIcon}) center/contain no-repeat;
  position: absolute;
  top: 16px;
  right: 20px;

  @media screen and (min-width: 768px) {
    top: 27px;
    right: 34px;
  }
`;

const MenuNav = styled.div`
  width: 80vw;
  max-width: 300px;
  height: 18vw;
  max-height: 60px;
  background: url(${menuNavBg}) center/contain no-repeat;
  margin: 8px auto;
  color: #ffffff;
  font-size: ${props => (props.locale === "en" || props.locale === "ja" ? "16px" : "20px")};
  font-weight: 700;
  padding-right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  a {
    &,
    &:active,
    &:focus {
      color: #ffffff;
    }
  }
`;

const MenuSNS = styled.a`
  width: 80vw;
  max-width: 300px;
  height: 14vw;
  max-height: 50px;
  margin: 10px auto;
  display: block;
  background: url(${props => (props.sns === "facebook" ? menuSNSFacebook : menuSNSTwitter)})
    center/contain no-repeat;
`;

const MenuLangSwitcher = styled.div`
  width: 84vw;
  max-width: 330px;
  height: 18vw;
  max-height: 60px;
  background: url(${menuLangBg}) center/contain no-repeat;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;

  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }

  span {
    margin: 6px;
  }
`;

export default function NavbarMobile({ toRoot = false }) {
  const intl = useIntl();
  const { locale, formatMessage } = intl;
  const [openMenu, setOpenMenu] = useState(false);

  const handleScrollTo = useCallback(
    id => {
      if (toRoot) {
        if (typeof window !== "undefined") {
          window.sessionStorage.setItem("anchor", id);
          window.location.href = `../../${locale}`;
        }
      } else {
        if (typeof window !== "undefined") {
          const element = window.document.getElementById(id);
          if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
      setOpenMenu(false);
    },
    [toRoot, locale]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const anchor = window.sessionStorage.getItem("anchor");
      if (anchor) {
        handleScrollTo(anchor);
        window.sessionStorage.removeItem("anchor");
      }
    }
  }, [handleScrollTo]);

  const getLink = lang => {
    if (typeof window !== "undefined") {
      if (window.location.href.includes("character/")) {
        const characterName = window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
        return `/${lang}/character/${characterName}`;
      }
    }

    return LANGS_URLS_FOR_ROOT[lang];
  };

  return (
    <MenuWrapper>
      {!openMenu && (
        <MenuIcon
          onClick={() => {
            setOpenMenu(true);
          }}
          src={menuIcon}
          alt="menu"
        />
      )}
      {openMenu && (
        <MenuPanel>
          <MenuCloseBtn
            onClick={() => {
              setOpenMenu(false);
            }}></MenuCloseBtn>

          {config.navbar.map((nav, index) => (
            <React.Fragment key={index}>
              {nav.link[0] === "#" ? (
                <MenuNav onClick={() => handleScrollTo(nav.link.slice(1))} locale={locale}>
                  {formatMessage({ id: `nav-${nav.key}` })}
                </MenuNav>
              ) : (
                <MenuNav locale={locale}>
                  <OuterLink
                    href={nav.link[locale] || nav.link.en}
                    text={formatMessage({ id: `nav-${nav.key}` })}
                  />
                </MenuNav>
              )}
            </React.Fragment>
          ))}

          <MenuSNS
            sns="facebook"
            href={config.footer.snsLinks.facebook[locale]}
            target="_blank"
            rel="noreferrer noopener"></MenuSNS>
          <MenuSNS
            sns="twitter"
            href={config.footer.snsLinks.twitter[locale]}
            target="_blank"
            rel="noreferrer noopener"></MenuSNS>

          <MenuLangSwitcher>
            {LANGS_URLS_AND_DISPLAY.map((lang, index) => (
              <React.Fragment key={index}>
                {/* TODO: maybe this can be replaced by changeLocale function of gatsby-plugin-intl, but there are bugs with redirection */}
                <Link
                  to={getLink(lang.key)}
                  style={{ color: locale === lang.key ? "#ff0a94" : "#ffffff" }}>
                  {lang.display}
                </Link>
                {index !== LANGS_URLS_AND_DISPLAY.length - 1 && <span>\</span>}
              </React.Fragment>
            ))}
          </MenuLangSwitcher>
        </MenuPanel>
      )}
    </MenuWrapper>
  );
}
