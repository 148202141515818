module.exports = {
  navbar: [
    {
      key: "news",
      link: {
        "zh-Hant": "https://www.cht.forum.tenkafuma.com/",
        "zh-Hans": "https://www.chs.forum.tenkafuma.com/",
        en: "https://www.eng.forum.tenkafuma.com/",
        ja: "https://www.jp.forum.tenkafuma.com/",
        ko: "https://www.kr.forum.tenkafuma.com/",
      },
    },
    {
      key: "intro",
      link: "#intro",
    },
    {
      key: "characters",
      link: "#characters",
    },
    {
      key: "faq",
      link: {
        "zh-Hant": `${process.env.GATSBY_ROOT_PATH}/zh-Hant/faq`,
        "zh-Hans": `${process.env.GATSBY_ROOT_PATH}/zh-Hans/faq`,
        en: `${process.env.GATSBY_ROOT_PATH}/en/faq`,
        ja: `${process.env.GATSBY_ROOT_PATH}/ja/faq`,
        ko: `${process.env.GATSBY_ROOT_PATH}/ko/faq`,
      },
    },
  ],
  homePage: {
    downloadLinks: {
      ios: "https://l.tyrantdb.com/v8raJPao",
      android: "https://l.tyrantdb.com/v8raJPao",
      pc: "https://res06.bignox.com/player/dev/exe/20210120/a8331dd6bd8645dd8081cd27c8806882.exe",
    },
    snsLinks: {
      facebook: {
        "zh-Hant": "https://www.facebook.com/groups/2707800699549152",
        "zh-Hans": "https://www.facebook.com/groups/2707800699549152",
        en: "https://www.facebook.com/groups/223304666122906/",
        ja: "https://www.facebook.com/groups/255824189239623/",
        ko: "https://www.facebook.com/groups/223304666122906/",
      },
      discord: {
        "zh-Hant": "https://discord.gg/RRRABMtHYj",
        "zh-Hans": "https://discord.gg/RRRABMtHYj",
        en: "https://discord.gg/wJqXQjVnw4",
        ja: "https://discord.gg/AppXZMeaQq",
        ko: "https://discord.com/invite/CUSkSBseAa",
      },
    },
  },
  footer: {
    snsLinks: {
      facebook: {
        "zh-Hant": "https://www.facebook.com/groups/2707800699549152",
        "zh-Hans": "https://www.facebook.com/groups/2707800699549152",
        en: "https://www.facebook.com/groups/223304666122906/",
        ja: "https://www.facebook.com/groups/255824189239623/",
        ko: "https://www.facebook.com/groups/223304666122906/",
      },
      twitter: {
        "zh-Hant": "https://twitter.com/tenkafuma",
        "zh-Hans": "https://twitter.com/tenkafuma",
        en: "https://twitter.com/tenkafumaE",
        ja: "https://twitter.com/tenkafuma",
        ko: "https://twitter.com/TenkafumaK",
      },
    },
    // termsLinks: {
    //   tos: {
    //     "zh-Hant": "https://www.tenkafuma.com/term-of-use-cht",
    //     "zh-Hans": "https://www.tenkafuma.com/term-of-use-chs",
    //     en: "https://www.tenkafuma.com/term-of-use-en",
    //     ja: "https://www.tenkafuma.com/term-of-use-jp",
    //     ko: "https://www.tenkafuma.com/term-of-use-en",
    //   },
    //   privacy: {
    //     "zh-Hant": "https://www.tenkafuma.com/privacy-policy-cht",
    //     "zh-Hans": "https://www.tenkafuma.com/privacy-policy-chs",
    //     en: "https://www.tenkafuma.com/privacy-policy-en",
    //     ja: "https://www.tenkafuma.com/privacy-policy-jp",
    //     ko: "https://www.tenkafuma.com/privacy-policy-en",
    //   },
    // },
  },
  mailto: {
    "zh-Hant":
      "mailto:service@sg-arts.com?subject=%e5%a4%a9%e4%b8%8b%e5%b8%83%e9%ad%94%20%e6%88%91%e6%9c%89%e7%96%91%e5%95%8f%21&amp;body=%e9%ad%94%e7%8e%8b%e5%a4%a7%e4%ba%ba~%e7%82%ba%e7%a2%ba%e4%bf%9d%e6%88%91%e5%80%91%e5%8f%af%e4%bb%a5%e6%9b%b4%e5%bf%ab%e5%8d%94%e5%8a%a9%e6%82%a8%21%20%e6%88%91%e5%80%91%e9%9c%80%e8%a6%81%e9%ad%94%e7%8e%8b%e5%a4%a7%e4%ba%ba%e6%8f%90%e4%be%9b%e4%bb%a5%e4%b8%8b%e8%b3%87%e8%a8%8a~~%0a%0a-%20%e7%9b%ae%e5%89%8d%e4%bd%bf%e7%94%a8%e7%9a%84%e8%a3%9d%e7%bd%ae%e5%90%8d%e7%a8%b1%e4%bb%a5%e5%8f%8a%e8%a3%9d%e7%bd%ae%e7%89%88%e6%9c%ac%20%28%e5%a6%82%3a%20Samsung%20Galaxy%20S8%20Android%209%29%0a-%20%e9%81%8a%e6%88%b2%e5%85%a7UID%20%28%e9%bb%9e%e9%81%b8%e9%81%8a%e6%88%b2%e5%85%a7%e5%b7%a6%e4%b8%8a%e8%a7%92%e7%ad%89%e7%b4%9a%e8%b3%87%e8%a8%8a%e4%be%bf%e5%8f%af%e4%bb%a5%e6%9f%a5%e7%9c%8b%e4%bd%a0%e7%9a%84UID%e5%94%b7%29%0a-%20%e9%81%8a%e6%88%b2%e5%b9%b3%e5%8f%b0%20%28EROLABS%20%e6%88%96%e6%98%af%20%e5%b7%a5%e5%8f%a3R18%29%0a-%20%e5%b9%b3%e5%8f%b0%e5%b8%b3%e8%99%9f%0a-%20%e7%96%91%e5%95%8f%e5%85%a7%e5%ae%b9%20%28%e6%9c%89%e9%99%84%e4%b8%8a%e6%88%aa%e5%9c%96%e6%9b%b4%e5%a5%bd%e5%94%b7%21%29",
    "zh-Hans":
      "mailto:service@sg-arts.com?subject=%e5%a4%a9%e4%b8%8b%e5%b8%83%e9%ad%94%20%e6%88%91%e6%9c%89%e7%96%91%e5%95%8f%21&amp;body=%e9%ad%94%e7%8e%8b%e5%a4%a7%e4%ba%ba~%e7%82%ba%e7%a2%ba%e4%bf%9d%e6%88%91%e5%80%91%e5%8f%af%e4%bb%a5%e6%9b%b4%e5%bf%ab%e5%8d%94%e5%8a%a9%e6%82%a8%21%20%e6%88%91%e5%80%91%e9%9c%80%e8%a6%81%e9%ad%94%e7%8e%8b%e5%a4%a7%e4%ba%ba%e6%8f%90%e4%be%9b%e4%bb%a5%e4%b8%8b%e8%b3%87%e8%a8%8a~~%0a%0a-%20%e7%9b%ae%e5%89%8d%e4%bd%bf%e7%94%a8%e7%9a%84%e8%a3%9d%e7%bd%ae%e5%90%8d%e7%a8%b1%e4%bb%a5%e5%8f%8a%e8%a3%9d%e7%bd%ae%e7%89%88%e6%9c%ac%20%28%e5%a6%82%3a%20Samsung%20Galaxy%20S8%20Android%209%29%0a-%20%e9%81%8a%e6%88%b2%e5%85%a7UID%20%28%e9%bb%9e%e9%81%b8%e9%81%8a%e6%88%b2%e5%85%a7%e5%b7%a6%e4%b8%8a%e8%a7%92%e7%ad%89%e7%b4%9a%e8%b3%87%e8%a8%8a%e4%be%bf%e5%8f%af%e4%bb%a5%e6%9f%a5%e7%9c%8b%e4%bd%a0%e7%9a%84UID%e5%94%b7%29%0a-%20%e9%81%8a%e6%88%b2%e5%b9%b3%e5%8f%b0%20%28EROLABS%20%e6%88%96%e6%98%af%20%e5%b7%a5%e5%8f%a3R18%29%0a-%20%e5%b9%b3%e5%8f%b0%e5%b8%b3%e8%99%9f%0a-%20%e7%96%91%e5%95%8f%e5%85%a7%e5%ae%b9%20%28%e6%9c%89%e9%99%84%e4%b8%8a%e6%88%aa%e5%9c%96%e6%9b%b4%e5%a5%bd%e5%94%b7%21%29",
    en:
      "mailto:service@sg-arts.com?subject=Tenkafu%20MA%2c%20I%20have%20a%20question%21&amp;body=My%20Lord%2c%20to%20make%20sure%20we%20can%20assist%20you%20faster%2c%20please%20provide%20us%20the%20following%20information%21%0a%0a-%20Device%20Model%20and%20OS%20version%20%28For%20example%2c%20Samsung%20Galaxy%20S8%20Android%209%29%0a-%20In-game%20UID%20%28Click%20the%20Level%20information%20in%20the%20top-left%20corner%20to%20check%20your%20UID%29%0a-%20Your%20platform%20%28EROLABS%20or%20%e5%b7%a5%e5%8f%a3R18%29%0a-%20Platform%20Account%20%28the%20email%20you%20used%20to%20register%20your%20platform%20account%29%0a-%20Description%20of%20your%20question%20%28Better%20with%20screenshot%21%29",
    ja:
      "mailto:service@sg-arts.com?subject=%e5%a4%a9%e4%b8%8b%e5%b8%83%e9%ad%94%20%e8%b3%aa%e5%95%8f%e3%81%8c%e3%81%82%e3%82%8a%e3%81%be%e3%81%99%ef%bc%81&amp;body=%e9%ad%94%e7%8e%8b%e6%a7%98%ef%bd%9e%e3%81%99%e3%81%90%e3%81%ab%e9%ad%94%e7%8e%8b%e6%a7%98%e3%81%ae%e3%81%8a%e5%bd%b9%e3%81%ab%e7%ab%8b%e3%81%a6%e3%82%8b%e3%82%88%e3%81%86%e3%80%81%e3%81%93%e3%81%a1%e3%82%89%e3%81%ae%e6%83%85%e5%a0%b1%e3%82%92%e6%95%99%e3%81%88%e3%81%a6%e3%81%8f%e3%81%a0%e3%81%95%e3%81%84%e3%81%be%e3%81%9b%e3%82%93%e3%81%8b%ef%bc%9f%0a%0a-%20%e7%8f%be%e5%9c%a8%e4%bd%bf%e7%94%a8%e3%81%97%e3%81%a6%e3%81%84%e3%82%8b%e3%83%87%e3%83%90%e3%82%a4%e3%82%b9%e3%81%ae%e5%90%8d%e7%a7%b0%e3%81%a8%e3%83%87%e3%83%90%e3%82%a4%e3%82%b9%e3%81%ae%e3%83%90%e3%83%bc%e3%82%b8%e3%83%a7%e3%83%b3%20%28%e4%be%8b%3a%20Samsung%20Galaxy%20S8%e3%80%81Android%209%29%0a-%20%e3%82%b2%e3%83%bc%e3%83%a0%e5%86%85UID%20%28%e3%82%b2%e3%83%bc%e3%83%a0%e5%86%85%e5%b7%a6%e4%b8%8a%e3%81%ae%e3%83%ac%e3%83%99%e3%83%ab%e6%83%85%e5%a0%b1%e3%82%92%e3%82%bf%e3%83%83%e3%83%97%e3%81%99%e3%82%8b%e3%81%a8%e9%ad%94%e7%8e%8b%e6%a7%98%e3%81%aeUID%e3%81%8c%e8%a6%8b%e3%82%89%e3%82%8c%e3%81%be%e3%81%99%29%0a-%20%e3%82%b2%e3%83%bc%e3%83%a0%e3%83%97%e3%83%a9%e3%83%83%e3%83%88%e3%83%9b%e3%83%bc%e3%83%a0%20%28EROLABS%20%e3%81%be%e3%81%9f%e3%81%af%20%e5%b7%a5%e5%8f%a3R18%29%0a-%20%e3%83%97%e3%83%a9%e3%83%83%e3%83%88%e3%83%9b%e3%83%bc%e3%83%a0%e3%81%ae%e3%82%a2%e3%82%ab%e3%82%a6%e3%83%b3%e3%83%88%0a-%20%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b%e5%86%85%e5%ae%b9%20%28%e3%82%b9%e3%82%af%e3%83%aa%e3%83%bc%e3%83%b3%e3%82%b7%e3%83%a7%e3%83%83%e3%83%88%e3%82%82%e6%b7%bb%e4%bb%98%e3%81%97%e3%81%a6%e3%82%82%e3%82%89%e3%81%88%e3%82%8b%e3%81%a8%e5%ac%89%e3%81%97%e3%81%84%e3%81%a7%e3%81%99%ef%bc%81%29",
    ko:
      "mailto:service@sg-arts.com?subject=%ed%85%90%ec%b9%b4%ed%91%b8%eb%a7%88%ec%97%90%20%eb%8c%80%ed%95%9c%20%eb%ac%b8%ec%9d%98%20%ec%82%ac%ed%95%ad%ec%9d%b4%20%ec%9e%88%ec%96%b4%ec%9a%94%21&amp;body=%eb%a7%88%ec%99%95%eb%8b%98~%20%eb%8d%94%ec%9a%b1%20%ec%8b%a0%ec%86%8d%ed%95%9c%20%eb%ac%b8%ec%9d%98%20%ec%a7%80%ec%9b%90%ec%9d%84%20%ec%9c%84%ed%95%b4%2c%20%ec%a0%80%ed%9d%ac%ec%97%90%ea%b2%8c%20%ec%95%84%eb%9e%98%ec%9d%98%20%ec%a0%95%eb%b3%b4%eb%a5%bc%20%ec%a0%9c%ea%b3%b5%ed%95%b4%20%ec%a3%bc%ec%84%b8%ec%9a%94~~%0a%0a-%20%ed%98%84%ec%9e%ac%20%ec%82%ac%ec%9a%a9%ec%a4%91%20%ec%9d%b4%ec%8b%a0%20%ea%b8%b0%ea%b8%b0%ec%99%80%20OS%eb%b2%84%ec%a0%84%20%28%ec%98%88%3a%20Samsung%20Galaxy%20S8%20Android%209%29%0a-%20%ed%94%8c%eb%a0%88%ec%9d%b4%ec%96%b4%eb%8b%98%ec%9d%98%20UID%20%28%ea%b2%8c%ec%9e%84%20%eb%82%b4%20%ec%a2%8c%ec%b8%a1%20%ec%83%81%eb%8b%a8%20%ec%ba%90%eb%a6%ad%ed%84%b0%20%eb%a0%88%eb%b2%a8%20%ec%a0%95%eb%b3%b4%eb%a5%bc%20%eb%88%8c%eb%9f%ac%ec%a3%bc%ec%8b%9c%eb%a9%b4%20UID%eb%a5%bc%20%ed%99%95%ec%9d%b8%ed%95%98%ec%8b%a4%20%ec%88%98%20%ec%9e%88%ec%8a%b5%eb%8b%88%eb%8b%a4.%29%0a-%20%ea%b2%8c%ec%9e%84%20%ed%94%8c%eb%9e%ab%ed%8f%bc%20%28EROLABS%20%eb%98%90%eb%8a%94%20%e5%b7%a5%e5%8f%a3R18%29%0a-%20%ed%94%8c%eb%9e%ab%ed%8f%bc%20%ec%95%84%ec%9d%b4%eb%94%94%0a-%20%eb%ac%b8%ec%9d%98%eb%82%b4%ec%9a%a9%20%28%ec%8a%a4%ed%81%ac%eb%a6%b0%ec%83%b7%ec%9d%84%20%ec%b2%a8%eb%b6%80%ed%95%b4%20%ec%a3%bc%ec%8b%9c%eb%a9%b4%20%eb%8d%94%ec%9a%b1%20%ec%a2%8b%ec%95%84%ec%9a%94%21%29",
  },
};
