import { useEffect } from "react";
import { LANGS } from "../utils/languages";

const getDefaultLanguage = () => {
  if (typeof window !== "undefined") {
    // if language preference is set
    const lang_from_storage = window.localStorage.getItem("gatsby-intl-language");
    if (LANGS.includes(lang_from_storage)) return lang_from_storage;

    // else: get language preference from browser navigator
    const lang_from_navigator = window.navigator.language.toLowerCase();
    switch (lang_from_navigator.split("-")[0]) {
      case "ja":
        return "ja";
      case "ko":
        return "ko";
      case "zh": {
        if (lang_from_navigator === "zh-tw") return "zh-Hant";
        else return "zh-Hans";
      }
      case "en":
      default:
        return "en";
    }
  }
};

const useRedirectByLang = () => {
  useEffect(() => {
    const url = window.location.href;
    const hasLangInURL = LANGS.reduce((l, r) => {
      return l || url.includes(`/${r}`);
    }, false);

    if (!hasLangInURL) {
      const lang = getDefaultLanguage();
      window.localStorage.setItem("gatsby-intl-language", lang);

      let pageURL = `${process.env.GATSBY_ROOT_PATH}/${lang}`;
      if (url.includes("privacy-policy")) pageURL += "/privacy-policy";
      else if (url.includes("term-of-use")) pageURL += "/term-of-use";
      else if (url.includes("character/")) pageURL += `/${url.slice(url.indexOf("character/"))}`;

      window.location.href = pageURL;
    }
  }, []);
};

export default useRedirectByLang;
